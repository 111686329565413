import './App.css';
import Landing from './pages/LandPage';
import { NavbarContainer, AppLogo, CustomButton } from './styles/LandingPageStyle';


function App() {
  const pageURL = 'https://apps.apple.com/app/gixel/id6447103492';

  return (
    <div>
      <NavbarContainer>
        <AppLogo>
          <img src={process.env.PUBLIC_URL + '/icon-hiegh.png'} alt="App Logo" />
        </AppLogo>
        <a href={pageURL} target="_blank">
        <CustomButton>Download Gixel</CustomButton>
      </a>
      </NavbarContainer>
      <Landing />
    </div>
  );
}

export default App;
