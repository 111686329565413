import styled from "styled-components";


export const LandingContainer = styled.main`
  background-color: white; 
  color: black; 
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding-top: 80px;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const LargeTitleText = styled.div`
  font-size: 80px;
    line-height: 1.05;
    font-weight: 600;
    letter-spacing: -0.015em;
    font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
  color: black;
  background: black;
  text-align: center;
  font-weight: bold;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  @media (max-width: 768px) {
    font-size: 60px;
  }

  @media (max-width: 576px) {
    font-size: 40px;
  }
`;

export const NormaTitleText = styled.div`
  font-size: 30px;
  font-weight: 600;
  font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
  color: black;
  text-align: center;
  font-weight: bold;
  margin-top: 0px
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

export const CustomButton = styled.button`
background: linear-gradient(180deg, #007AFF 20.59%, #5E5CE6 85.35%);
border: 2px solid #007AFF; /* Add a border */
border-radius: 34px;
padding: 10px 20px;
color: #FFFFFF; 
font-weight: bold;
filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.35));
cursor: pointer;
margin-right: 20px;

&:hover {
  /* Add hover styles if needed */
  background: linear-gradient(180deg, #007AFF 50%, #007AFF 50%);
}
`;

export const StyledImage = styled.img`
  width: 60vw; 
  height: auto; 
  margin: 20px 0;
  @media (max-width: 768px) { 
  width: 90vw;
}
`;

export const NavbarContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    `;

export const AppLogo = styled.div`
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin: 10px;
  }
`;

export const Logo = styled.div`
  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: 10px;
  }
`;