import { LargeTitleText, NormaTitleText, LandingContainer, StyledImage, Logo } from "../styles/LandingPageStyle";

export default function Landing() {
  return (
    <LandingContainer>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Logo>
          <img src={process.env.PUBLIC_URL + '/app-icon.png'} alt="App Logo" />
        </Logo>
        <LargeTitleText>
          Gixel
        </LargeTitleText>
      </div>

      <StyledImage
        src={process.env.PUBLIC_URL + '/gixel-poster.png'}
        alt="gixel"
      />

      <NormaTitleText>
        <span style={{ color: 'black' }}>An iOS application<br></br> designed for </span>
        <span style={{ color: 'blue' }}>multimedia</span>
        <span style={{ color: '#34C759' }}> content creation</span>
      </NormaTitleText>
    </LandingContainer>
  );
}

